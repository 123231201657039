import React from 'react'
import { Translate } from 'ns-react-i18next'

import SlideDog1 from '../images/slide-dog-1.png';
import SlideDog2 from '../images/slide-dog-2.png';
import SlideDog3 from '../images/slide-dog-3.png';
import SlideDog4 from '../images/slide-dog-4.png';
import SlideDog6 from '../images/slide-dog-6.png';

import PhoneSlide1 from '../images/phone-slide-1.png';
import PhoneSlide2 from '../images/phone-slide-2.png';
import PhoneSlide3 from '../images/phone-slide-3.png';
import PhoneSlide4 from '../images/phone-slide-4.png';
import PhoneSlide5 from '../images/phone-slide-5.png';
import PhoneSlide6 from '../images/phone-slide-6.png';

import PhoneSlide1EN from '../images/phone-slide-1-en.png';
import PhoneSlide2EN from '../images/phone-slide-2-en.png';
import PhoneSlide3EN from '../images/phone-slide-3-en.png';
import PhoneSlide4EN from '../images/phone-slide-4-en.png';
import PhoneSlide5EN from '../images/phone-slide-5-en.png';
import PhoneSlide6EN from '../images/phone-slide-6-en.png';

import ManImage from '../images/man.png';
import CameraImage from '../images/camera.png';
import NetImage from '../images/net.png';
import BulbImage from '../images/bulb.png';
import SheetImage from '../images/sheet.png';
import PencilImage from '../images/pencil.png';
import HandImageBlack from '../images/hand.png';
import MagnifierImage from '../images/magnifier.png';
import QuestionImage from '../images/question.png';
import ExclamationImage from '../images/exclamation.png';
import SettingImage from '../images/setting.png';
import HearthImage from '../images/hearth.png';
import Arrow1Image from '../images/arrow1.png';
import Arrow2Image from '../images/arrow2.png';
import ChatBubleRowImage from '../images/chat-buble-row.png';
import ChatBublePointImage from '../images/chat-buble-point.png';
import TreeImage from '../images/tree.png';
import CloudImage from '../images/cloud.png';
import CloudSunImage from '../images/cloudsun.png';
import MarkerImage from '../images/marker.png';
import LineImage from '../images/line.png';
import ThumbImage from '../images/thumb.png';

import PawImage from '../images/section-three/paw-inactive.png';
import PawImageActive from '../images/section-three/paw-active.png';

const PawList = ({ activeIndex }) => (
    <div className='paw-list hide-mobile'>
        <img className='paw-image' style={{transform: 'rotate(25deg)'}} src={activeIndex === 0 ? PawImageActive : PawImage}></img>
        <img className='paw-image paw-image__left' style={{transform: 'rotate(-25deg)'}} src={activeIndex === 1 ? PawImageActive : PawImage}></img>
        <img className='paw-image' style={{transform: 'rotate(25deg)'}} src={activeIndex === 2 ? PawImageActive : PawImage}></img>
        <img className='paw-image paw-image__left' style={{transform: 'rotate(-25deg)'}} src={activeIndex === 3 ? PawImageActive : PawImage}></img>
        <img className='paw-image' style={{transform: 'rotate(25deg)'}} src={activeIndex === 4 ? PawImageActive : PawImage}></img>
        <img className='paw-image paw-image__left' style={{transform: 'rotate(-25deg)'}} src={activeIndex === 5 ? PawImageActive : PawImage}></img>
    </div>
);

const SlideDesktop = (props) => (
<div className={`container ${props.className}`} >
  <div className="swiper-container">
    <div className="swiper-wrapper">
      <div className="swiper-slide">
        <div className='slide-container slide-one'>
          <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide1 : PhoneSlide1EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog1}></img>
            <img className='slide-one__man-image hide-1300' src={ManImage}></img>
            <img className='slide-one__bulb-image hide-1300' src={BulbImage}></img>
            <img className='slide-one__camera-image hide-1300' src={CameraImage}></img>
            <img className='slide-one__net-image hide-1300' src={NetImage}></img>
            <img className='slide-one__sheet-image hide-1300' src={SheetImage}></img>
            <img className='slide-one__pencil-image hide-1300' src={PencilImage}></img>
            <img className='slide-one__hand-image hide-1300' src={HandImageBlack}></img>
          </div>
          <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step1</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step1-text1</Translate> <span><Translate>section-three_step1-text2</Translate></span></p>
            <PawList activeIndex={0} />
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className='slide-container slide-two'>
          <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide2 : PhoneSlide2EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog2}></img>
            <img className='slide-two__man-image1 hide-1300' src={ManImage}></img>
            <img className='slide-two__man-image2 hide-1300' src={ManImage}></img>
            <img className='slide-two__man-image3 hide-1300' src={ManImage}></img>
            <img className='slide-two__magnifier-image hide-1300' src={MagnifierImage}></img>
            <img className='slide-two__exclamation-image hide-1300' src={ExclamationImage}></img>
            <img className='slide-two__question-image hide-1300' src={QuestionImage}></img>
            <img className='slide-two__setting-image hide-1300' src={SettingImage}></img>
            <img className='slide-two__setting-image2 hide-1300' src={SettingImage}></img>
          </div>
          <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step2</Translate></span></div>
            <p className='slide-description'><span><Translate>section-three_step2-text1</Translate></span> <Translate>section-three_step2-text2</Translate></p>
            <PawList activeIndex={1} />
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className='slide-container slide-three'>
          <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide3 : PhoneSlide3EN} className='slide-container__phone-image' />  
            <img className='slide-container__dog-image' src={SlideDog3}></img>
            <img className='slide-three__man-image1 hide-1300' src={ManImage}></img>
            <img className='slide-three__hearth-image1 hide-1300' src={HearthImage}></img>
            <img className='slide-three__hearth-image2 hide-1300' src={HearthImage}></img>
            <img className='slide-three__hearth-image3 hide-1300' src={HearthImage}></img>
            <img className='slide-three__arrow1-image4 hide-1300' src={Arrow1Image}></img>
            <img className='slide-three__arrow1-image1 hide-1300' src={Arrow1Image}></img>
            <img className='slide-three__arrow1-image2 hide-1300' src={Arrow1Image}></img>
            <img className='slide-three__arrow1-image3 hide-1300' src={Arrow1Image}></img>
            <img className='slide-three__arrow2-image1 hide-1300' src={Arrow2Image}></img>
            <img className='slide-three__arrow2-image2 hide-1300' src={Arrow2Image}></img>
            <img className='slide-three__arrow2-image3 hide-1300' src={Arrow2Image}></img>
          </div>
          <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step3</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step3-text1</Translate><span><Translate>section-three_step3-text2</Translate></span></p>
            <PawList activeIndex={2} />
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className='slide-container slide-four'>
          <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide4 : PhoneSlide4EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog4}></img>
            <img className='slide-four__man-image1 hide-1300' src={ManImage}></img>
            <img className='slide-four__man-image2 hide-1300' src={ManImage}></img>
            <img className='slide-four__cloud-row hide-1300' src={ChatBubleRowImage}></img>
            <img className='slide-four__cloud-point hide-1300' src={ChatBublePointImage}></img>
          </div>
          <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step4</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step4-text1</Translate><span><Translate>section-three_step4-text2</Translate></span></p>
            <PawList activeIndex={3} />
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className='slide-container slide-five'>
          <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide5 : PhoneSlide5EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog3}></img>
            <img className='slide-five__tree-image1 hide-1300' src={TreeImage}></img>
            <img className='slide-five__tree-image2 hide-1300' src={TreeImage}></img>
            <img className='slide-five__marker-image1 hide-1300' src={MarkerImage}></img>
            <img className='slide-five__marker-image2 hide-1300' src={MarkerImage}></img>
            <img className='slide-five__line-image hide-1300' src={LineImage}></img>
            <img className='slide-five__cloud-image hide-1300' src={CloudImage}></img>
            <img className='slide-five__cloud-sun-image hide-1300' src={CloudSunImage}></img>
          </div>
          <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step5</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step5-text1</Translate><span><Translate>section-three_step5-text2</Translate></span></p>
            <PawList activeIndex={4} />
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className='slide-container slide-six'>
          <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide6 : PhoneSlide6EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog6}></img>
            <img className='slide-six__thumb-image hide-1300' src={ThumbImage}></img>
            <img className='slide-six__hearth-image1 hide-1300' src={HearthImage}></img>
            <img className='slide-six__hearth-image2 hide-1300' src={HearthImage}></img>
            <img className='slide-six__hearth-image3 hide-1300' src={HearthImage}></img>
            <img className='slide-six__hearth-image4 hide-1300' src={HearthImage}></img>
          </div>
          <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step6</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step6-text1</Translate> <span><Translate>section-three_step6-text2</Translate></span></p>
            <PawList activeIndex={5} />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);

export default SlideDesktop;