import React from 'react'
import { Translate } from 'ns-react-i18next'

import SlideDog1 from '../images/slide-dog-1.png';
import SlideDog2 from '../images/slide-dog-2.png';
import SlideDog3 from '../images/slide-dog-3.png';
import SlideDog4 from '../images/slide-dog-4.png';
import SlideDog6 from '../images/slide-dog-6.png';

import PhoneSlide1 from '../images/phone-slide-1.png';
import PhoneSlide2 from '../images/phone-slide-2.png';
import PhoneSlide3 from '../images/phone-slide-3.png';
import PhoneSlide4 from '../images/phone-slide-4.png';
import PhoneSlide5 from '../images/phone-slide-5.png';
import PhoneSlide6 from '../images/phone-slide-6.png';

import PhoneSlide1EN from '../images/phone-slide-1-en.png';
import PhoneSlide2EN from '../images/phone-slide-2-en.png';
import PhoneSlide3EN from '../images/phone-slide-3-en.png';
import PhoneSlide4EN from '../images/phone-slide-4-en.png';
import PhoneSlide5EN from '../images/phone-slide-5-en.png';
import PhoneSlide6EN from '../images/phone-slide-6-en.png';

const SlideMobile = (props) => (
<div className={`container ${props.className}`} >
    <div className='slide-one slide'>
        <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide1 : PhoneSlide1EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog1}></img>
        </div>
        <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step1</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step1-text1</Translate> <span><Translate>section-three_step1-text2</Translate></span></p>
        </div>
    </div>
    <div className='slide-two slide'>
        <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide2 : PhoneSlide2EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog2}></img>
        </div>
        <div className='slide-container__description'>
            <div className='slide-step'><Translate>section-three_step2</Translate></div>
            <p className='slide-description'><span><Translate>section-three_step2-text1</Translate></span> <Translate>section-three_step2-text2</Translate></p>
        </div>
    </div>
    <div className='slide-three slide'>
        <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide3 : PhoneSlide3EN} className='slide-container__phone-image' />  
            <img className='slide-container__dog-image' src={SlideDog3}></img>
        </div>
        <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step3</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step3-text1</Translate><span><Translate>section-three_step3-text2</Translate></span></p>
        </div>
    </div>
    <div className='slide-four slide'>
        <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide4 : PhoneSlide4EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog4}></img>
        </div>
        <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step4</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step4-text1</Translate> <span><Translate>section-three_step4-text2</Translate></span></p>
        </div>
    </div>
    <div className='slide-five slide'>
        <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide5 : PhoneSlide5EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog3}></img>
        </div>
        <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step5</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step5-text1</Translate> <span><Translate>section-three_step5-text2</Translate></span></p>
        </div>
    </div>
    <div className='slide-six slide'>
        <div className='slide-container__phone'>
            <img src={props.lng === 'hu' ? PhoneSlide6 : PhoneSlide6EN} className='slide-container__phone-image' />
            <img className='slide-container__dog-image' src={SlideDog6}></img>
        </div>
        <div className='slide-container__description'>
            <div className='slide-step'><span><Translate>section-three_step6</Translate></span></div>
            <p className='slide-description'><Translate>section-three_step6-text1</Translate> <span><Translate>section-three_step6-text2</Translate></span></p>
        </div>
    </div>
</div>
);

export default SlideMobile;