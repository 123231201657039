import React from 'react';

const buttons = {
  hu: [
    {
      image: '/ios_hu.png',
      href: 'https://apps.apple.com/us/app/doggo/id1492043969'
    },
    {
      image: '/android_hu.png',
      href: 'https://play.google.com/store/apps/details?id=hu.doggoapp.doggoapp'
    }
  ],
  en: [
    {
      image: '/ios_en.png',
      href: 'https://apps.apple.com/us/app/doggo/id1492043969'
    },
    {
      image: '/android_en.png',
      href: 'https://play.google.com/store/apps/details?id=hu.doggoapp.doggoapp'
    }
  ]
}

export function PlayStoreButtons({ lng }) {
  const items = buttons[lng] ?? []

  return <div className="store-icons">
    {items.map(item => {
      return <a target="_blank" href={item.href}><img src={item.image} /></a>
    })}
  </div>
}