import { Link } from 'gatsby';
import * as i18n from 'i18next';
import { NamespaceProvider, Translate } from 'ns-react-i18next';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ReactGA from 'react-ga';
import ReCAPTCHA from 'react-google-recaptcha';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import Arrow1ImageWhite from '../images/arrow1-white.png';
import Arrow2ImageWhite from '../images/arrow2-white.png';
import BulbImageWhite from '../images/bulb-white.png';
import CloudImageWhite from '../images/cloud-white.png';
import HearthImageWhite from '../images/hearth-white.png';
import HearthImage from '../images/hearth.png';
import MailImage from '../images/mail.png';
import PhoneImageEN from '../images/phone-slide-5-en.png';
import PhoneImage from '../images/phone-slide-5.png';
import PointerBlackImage from '../images/pointer-black.png';
import PointerWhiteImage from '../images/pointer-white.png';
import GirlDog from '../images/section-five/girldog.png';
import DogImage3 from '../images/section-four/dog.png';
import DogImage from '../images/section-one/girlwithdog.png';
import DogImage2 from '../images/section-two/dog.png';
import DoggoLogo from '../images/section-two/doggo.png';
import HandImage from '../images/section-two/hand.png';
import SubscribeImageEN from '../images/subscribe-en.png';
import SubscribeImage from '../images/subscribe.png';
import CookieConsent from '../vendor/cookieConsent';
import Swiper from '../vendor/swiper';
import Layout from './layout';
import './layout.css';
import './main.css';
import SEO from './seo';
import SlideDesktop from './slideDesktop';
import SlideMobile from './slideMobile';
import { PlayStoreButtons } from './playStoreButtons';

const validator = require('email-validator');

const recaptchaRef = React.createRef();
const email = React.createRef();

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      showDialog: false,
      title: '',
      text: '',
      acceptedTerms: false,
      dialogType: 'success'
    };
  }
  componentDidMount() {
    ReactGA.initialize('UA-134456829-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.swiper = new Swiper('.swiper-container', {
      direction: 'vertical',
      slidesPerView: 1,
      spaceBetween: 0,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      touchReleaseOnEdges: true,
      mousewheel: {
        releaseOnEdges: false
      }
    });
    this.swiper.on('slideChange', () => {
      const activeIndex = this.swiper.activeIndex;
      this.setState(
        {
          currentSlide: activeIndex
        },
        () => {
          const index = this.state.currentSlide;
          const description = document.getElementsByClassName(
            'slide-description'
          )[index];

          description.classList.add('slide-left');
          if (index > 0) {
            const prevDescription = document.getElementsByClassName(
              'slide-description'
            )[index - 1];
            const nextDescription = document.getElementsByClassName(
              'slide-description'
            )[index + 1];

            prevDescription && prevDescription.classList.remove('slide-left');
            nextDescription && nextDescription.classList.remove('slide-left');
          } else {
            const nextDescription = document.getElementsByClassName(
              'slide-description'
            )[index + 1];
            nextDescription && nextDescription.classList.remove('slide-left');
          }
        }
      );
    });
  }

  handleSubscribe = () => {
    const value = email.current.value;
    const { acceptedTerms } = this.state;

    if (!acceptedTerms) {
      this.setState({
        showDialog: true,
        dialogType: 'warning',
        title: 'Oooops',
        text: i18n.t('accept_terms')
      });
      return;
    }

    if (value && validator.validate(value)) {
      recaptchaRef.current.execute();
    } else {
      this.setState({
        showDialog: true,
        dialogType: 'warning',
        title: 'Oooops',
        text: i18n.t('email_not_valid')
      });
    }
  };

  toggleAcceptTerms = () => {
    const { acceptedTerms } = this.state;
    this.setState({ acceptedTerms: !acceptedTerms });
  };

  onCaptchaChange = () => {
    const value = email.current.value;
    const { lng } = this.props;
    fetch('https://doggoapp.hu/api/subscribe', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: value, lng })
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        this.setState({
          showDialog: true,
          title: i18n.t('subscribe_success'),
          dialogType: 'success',
          text: i18n.t('thank_you')
        });
        recaptchaRef.current.reset();
      })
      .catch(err => {
        this.setState({
          showDialog: true,
          title: 'Ooooops',
          dialogType: 'error',
          text: i18n.t('email_error')
        });
        recaptchaRef.current.reset();
      });
  };
  render() {
    return (
      <Layout {...this.props}>
        <SEO
          title="dogGo - kutya gazdi közösségi applikáció"
          keywords={[`gatsby`, `application`, `react`]}
        />

        <NamespaceProvider ns="translations">
          <section id="section-1" className="section-one">
            <div className="container">
              <div className="text-container">
                <p className="section-one_text">
                  <Translate>section-one_text1</Translate>{' '}
                  <span className="text-yellow">
                    <Translate>section-one_text2</Translate>
                  </span>{' '}
                  <span>
                    <Translate>section-one_text3</Translate>
                  </span>
                </p>
                <PlayStoreButtons lng={this.props.lng} />
                {/* <AnchorLink href="#section-4">
                  <div className="btn-orange section-one__btn">
                    <Translate>subscribe</Translate>
                  </div>
                </AnchorLink> */}
              </div>
              <img className="section-one__dog-image" src={DogImage}></img>
              <img
                src={this.props.lng === 'hu' ? PhoneImage : PhoneImageEN}
                className="section-one__phone-image"
              />
            </div>
            <AnchorLink href="#section-2">
              <img className="pointer" src={PointerWhiteImage}></img>
            </AnchorLink>
          </section>
          <section id="section-2" className="section-two">
            <div className="container">
              <img
                className="section-two__phone-image"
                src={this.props.lng === 'hu' ? PhoneImage : PhoneImageEN}
              ></img>
              <div className="image-container">
                <img className="section-two__dog-image" src={DogImage2}></img>
                <img
                  className="section-two__arrow1-image hide-mobile hide-tablet"
                  src={Arrow1ImageWhite}
                ></img>
                <img
                  className="section-two__hearth1-image hide-mobile hide-tablet"
                  src={HearthImageWhite}
                ></img>
                <img
                  className="section-hearth2-image hide-mobile hide-tablet"
                  src={HearthImageWhite}
                ></img>
                <img
                  className="section-two__bulb-image hide-mobile hide-tablet"
                  src={BulbImageWhite}
                ></img>
                <img
                  className="section-two__cloud-image hide-mobile hide-tablet"
                  src={CloudImageWhite}
                ></img>
                <img
                  className="section-two__arrow2-image hide-mobile hide-tablet"
                  src={Arrow2ImageWhite}
                ></img>
              </div>
              <div className="text-container">
                <p className="section-two_text">
                  <Translate>section-two_text1</Translate>{' '}
                  <img src={DoggoLogo}></img>{' '}
                  <span>
                    <Translate>section-two_text2</Translate>
                  </span>{' '}
                  <Translate>section-two_text3</Translate>
                </p>
                <AnchorLink href="#section-3">
                  <div className="btn-white section-two__btn">
                    <Translate>section-two_btn-text</Translate>
                  </div>
                </AnchorLink>
                <img className="section-two__hand-image" src={HandImage}></img>
              </div>
            </div>
            <AnchorLink href="#section-3">
              <img className="pointer" src={PointerWhiteImage}></img>
            </AnchorLink>
          </section>
          <section
            id="section-3"
            className={`section-three color-${this.state.currentSlide}`}
          >
            <SlideDesktop
              lng={this.props.lng}
              className={'hide-mobile hide-tablet'}
            />
            <SlideMobile
              lng={this.props.lng}
              className={'show-mobile show-tablet'}
            />
          </section>
          {/* <section id="section-4" className="section-four">
            {this.state.currentSlide !== 5 && (
              <img
                className="pointer pointer-black hide-mobile"
                src={PointerBlackImage}
                onClick={() => this.swiper.slideNext()}
              ></img>
            )}
            {this.state.currentSlide === 5 && (
              <div className="pointer-with-text hide-mobile">
                <p>
                  <Translate>subscribe</Translate>
                </p>
                <AnchorLink href="#section-4">
                  <img
                    className="pointer"
                    src={PointerBlackImage}
                    onClick={() => this.swiper.slideNext()}
                  ></img>
                </AnchorLink>
              </div>
            )}
            <div className="container">
              <div className="image-container">
                <img className="section-four__dog-image" src={DogImage3}></img>
                <img
                  className="section-four__subscribe-image hide-mobile hide-tablet"
                  src={
                    this.props.lng === 'hu' ? SubscribeImage : SubscribeImageEN
                  }
                ></img>
                <img
                  className="section-four__hearth-image1 hide-mobile hide-tablet"
                  src={HearthImage}
                ></img>
                <img
                  className="section-four__hearth-image2 hide-mobile hide-tablet"
                  src={HearthImage}
                ></img>
                <img
                  className="section-four__hearth-image3 hide-mobile hide-tablet"
                  src={HearthImage}
                ></img>
              </div>
              <div className="text-container">
                <img
                  className="section-four__mail-image hide-mobile"
                  src={MailImage}
                ></img>
                <p>
                  <Translate>section-four__subscribe-text1</Translate>{' '}
                  <span>
                    <Translate>section-four__subscribe-text2</Translate>
                  </span>{' '}
                  <Translate>section-four__subscribe-text3</Translate>
                </p>
                <div className="section-four__subscribe">
                  <input
                    type="text"
                    ref={email}
                    placeholder={i18n.t('email')}
                  />
                  <div
                    className="subscribe-btn hide-mobile"
                    onClick={this.handleSubscribe}
                  >
                    <Translate>subscribe</Translate>
                  </div>
                </div>
                <div
                  className="subscribe-btn show-mobile"
                  onClick={this.handleSubscribe}
                >
                  <Translate>subscribe</Translate>
                </div>
                <div className="section-four__accept-terms">
                  <label className="checkbox-container">
                    {' '}
                    <Translate>section-four__subscribe-text4</Translate>{' '}
                    <Link to={`/privacy-policy`}>
                      <Translate>section-four__subscribe-text5</Translate>{' '}
                    </Link>
                    <Translate>section-four__subscribe-text6</Translate>{' '}
                    <a
                      href={
                        this.props.lng === 'hu'
                          ? '/adatkezeles.pdf'
                          : '/adatkezeles_en.pdf'
                      }
                      target="_blank"
                    ></a>
                    <input type="checkbox" onChange={this.toggleAcceptTerms} />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  onChange={this.onCaptchaChange}
                  size="invisible"
                  sitekey="6LduhIwUAAAAAK4QqsCzCKYZy4Hj85w9daau8Xrt"
                />
              </div>
            </div>
           </section> */}
          <section id="section-5" className="section-five">
            <div className="container">
              <div className="image-container">
                <img className="section-five__girl-image" src={GirlDog}></img>
              </div>
              <div className="text-container">
                <p>
                  <Translate>section-five__subscribe-text1</Translate>{' '}
                  <span>
                    <Translate>section-five__subscribe-text2</Translate>
                  </span>
                </p>
                <a
                  href={
                    this.props.lng === 'hu'
                      ? 'https://docs.google.com/forms/d/e/1FAIpQLScPYZ4FlZ93uiFuLTJ1f0M5EE7WAsvjE09sbQlBIOCDeCKtrA/viewform'
                      : 'https://goo.gl/forms/BEu4wHiAFZXTn25B3'
                  }
                  target="_blank"
                  className="section-five__btn"
                >
                  <Translate>section-five__subscribe-text3</Translate>
                </a>
              </div>
            </div>
          </section>
          <SweetAlert
            show={this.state.showDialog}
            title={this.state.title}
            text={this.state.text}
            type={this.state.dialogType}
            onConfirm={() => this.setState({ showDialog: false })}
          />
          <CookieConsent
            location="bottom"
            buttonText={i18n.t('accept')}
            cookieName="doggo-terms-of-use"
            buttonClasses="btn-cookie"
            style={{ background: '#2B373B' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}
          >
            <Translate>termsOfUseText</Translate>
          </CookieConsent>
        </NamespaceProvider>
      </Layout>
    );
  }
}

export default Main;
